<template>
  <div>

    <HeaderPersonal />

    <div class="section color mid-line map">
      <div data-w-id="8a817dec-33d8-ba64-0999-5e0ddc2f3a81" class="container map w-container">
        <div class="item-map-contein">
          <div class="tittle-form profil map">
            <h1 class="heading-form">Карта</h1>
          </div>
          <div class="w-layout-vflex item-map">
            <div class="code-embed w-embed w-script" ref="scriptContainer">
              <!-- ТУТ БЫЛ СКРИПТ -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />

  </div>
</template>

<script>
import { Footer, HeaderPersonal } from '/src/app/shared/components';
import loadjs from 'loadjs';

export default {
  name: 'Map',
  data() {
    return {
    };
  },
  components: {
    Footer,
    HeaderPersonal
  },
  mounted() {
    /* грузим jquery и webflow */
    loadjs(
        "https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=652921c910bae02d8870dddf",
        {
          success: () => {
            loadjs('./js/webflow.js', {
              success: () => {
              },
              error: () => {
                console.error('Failed to load Webflow');
              }
            });

          },
          error: () => {
            console.error('Failed to load jQuery');
          }
        }
    );

    const scriptUrl = 'https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3A11803d08e11bd3bf0c80344f6711413a9b36ed4186f1399cfc42b689257242a9&amp;width=100%25&amp;height=704&amp;lang=ru_RU&amp;scroll=true';
    if (!document.querySelector(`script[src="${scriptUrl}"]`)) {
      const script = document.createElement('script');
      script.async = true;
      script.src = scriptUrl;
      this.$refs.scriptContainer.appendChild(script);
    }
  },
  created() {
  },
  computed: {
  },
  methods: {
  }

};
</script>

<style>
.code-embed {
  width: 100%;
  height: 704px; /* или другой размер, который тебе нужен */
}
</style>
